import './index.scss'

import Swiper from 'swiper/js/swiper.min'
import 'swiper/css/swiper.min.css'

// header
!(function () {
  let $headerEl = $('.header')
  
  $(window).on('scroll', function () {
    if (window.pageYOffset === 0) {
      $headerEl.removeClass('active').addClass('normal')
    }
    else {
      $headerEl.removeClass('normal').addClass('active')
    }
  })
})()

// banner
// !(function () {
//   let swiper = new Swiper('.banner-swiper', {
//     loop: true,
//     speed: 800,
//     autoplay: {
//       delay: 5000,
//       disableOnInteraction: false
//     },
//     // required
//     watchSlidesProgress: true,
//     on: {
//       // 被背景设置 transition
//       setTransition (speed) {
//         for (var i = 0; i < this.slides.length; i++) {
//           this.slides[ i ].querySelector('b').style.transition =
//             speed + 'ms'
//         }
//       },
//       // 根据 slideProgress 实现视差效果
//       progress () {
//         for (let i = 0; i < this.slides.length; i++) {
//           let slideProgress = this.slides[ i ].progress
//           let innerOffset = this.width * 0.5
//           let innerTranslate = slideProgress * innerOffset
//           this.slides[ i ]
//             .querySelector('b')
//             .style
//             .transform = 'translate3d(' + innerTranslate + 'px, 0, 0)'
//         }
//       }
//     },
//     pagination: {
//       el: '.banner-pagination',
//       clickable: true,
//       bulletClass: 'asdlpkclzxcwwd',
//       bulletActiveClass: 'active',
//       modifierClass: 'asdwd5z45x6c7wd',
//       renderBullet: function (index, className) {
//         return `<i class="${ className }"></i>`
//       }
//     }
//   })
// })()

// project
!(function () {
  // 代表一组的数量，用于切换按钮
  const GROUP_LENGTH = 3
  
  let $tabItemElArr = $('.project-tab-item')
  let $bodyItemElArr = $('.project-body-item')
  let $prevBtnElArr = $('.project-area-control.prev')
  let $nextBtnElArr = $('.project-area-control.next')
  let swiperArr = []
  
  $('.project-swiper-s').each((i, el) => {
    swiperArr.push(
      new Swiper(el, {
        slidesPerView: 'auto',
        spaceBetween: 40
      })
    )
  })
  
  // tab 切换城市
  $tabItemElArr.on('click', function (e) {
    let i = $tabItemElArr.index($(this))
    
    e.preventDefault()
    
    $tabItemElArr.removeClass('active').eq(i).addClass('active')
    $bodyItemElArr.removeClass('active').eq(i).addClass('active')
    
    swiperArr[ i ].update()
  })
  
  // 模拟组切换
  $prevBtnElArr.on('click', function (e) {
    let i = $prevBtnElArr.index($(this))
    let swiper = swiperArr[ i ]
    let swiperCurrentI = swiper.realIndex
    
    e.preventDefault()
    
    if (swiperCurrentI >= GROUP_LENGTH) {
      swiper.slideTo(swiperCurrentI - GROUP_LENGTH)
    }
    else {
      swiper.slideTo(0)
    }
  })
  $nextBtnElArr.on('click', function (e) {
    let i = $nextBtnElArr.index($(this))
    let swiper = swiperArr[ i ]
    let swiperCurrentI = swiper.realIndex
    let maxI = swiper.slides.length - 1
    
    e.preventDefault()
    
    if (swiperCurrentI - GROUP_LENGTH < maxI) {
      swiper.slideTo(swiperCurrentI + GROUP_LENGTH)
    }
    else {
      swiper.slideTo(maxI)
    }
  })
})()

// activity
!(function () {
  let $indexEl = $('.activity-control-index')
  let picSwiper = new Swiper('.activity-pic', {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: false
  })
  let mainSwiper = new Swiper('.activity-swiper', {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    autoplay: {
      delay: 5000,
      disableOnInteraction: false
    },
    allowTouchMove: false,
    controller: {
      control: picSwiper
    },
    pagination: {
      el: '.activity-control-pagination',
      bulletClass: 'asdowidlkzxjkcl',
      bulletActiveClass: 'active',
      clickable: true,
      renderBullet: function (index, className) {
        return `<i class="${ className }"></i>`
      }
    },
    navigation: {
      nextEl: '.activity-control-btn.next',
      prevEl: '.activity-control-btn.prev'
    },
    on: {
      init () {
        this.emit('transitionStart')
      },
      transitionStart () {
        $indexEl.html(`${ this.realIndex + 1 } / ${ this.slides.length }`)
      }
    }
  })
})()

// news
!(function () {
  let $tabItemElArr = $('.news2-tab .comp-tab-item')
  let parentSwiper = new Swiper('.news2-swiper', {
    effect: 'fade',
    fadeEffect: {
      crossFade: true
    },
    allowTouchMove: false,
    preventClicks: false,
    on: {
      init () {
        this.emit('transitionStart')
      },
      transitionStart () {
        $tabItemElArr.removeClass('active').eq(this.realIndex).addClass('active')
      }
    }
  })
  
  // 子 swiper 图片切换
  $('.news2-pic-swiper').each(function (i, el) {
    let $el = $(el)
    let $paginationEl = $el.find('.comp-control-pagination')
    let swiper = new Swiper($el, {
      loop: true,
      effect: 'fade',
      autoplay: {
        delay: 5000,
        disableOnInteraction: false
      },
      fadeEffect: {
        crossFade: true
      },
      navigation: {
        disabledClass: 'disabled',
        nextEl: $el.find('.next'),
        prevEl: $el.find('.prev')
      },
      pagination: {
        el: $paginationEl,
        bulletClass: 'asdowidlwdwdwdwkzxjkcl',
        bulletActiveClass: 'active',
        clickable: true,
        renderBullet: function (index, className) {
          return `<i class="${ className }"></i>`
        }
      }
    })
  })
  
  // 点击切换父swiper
  $tabItemElArr.on('mouseenter', function () {
    parentSwiper.slideTo(
      $tabItemElArr.index($(this))
    )
  })
})()